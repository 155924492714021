@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;500;700&family=Poppins:wght@100;300;400;700&display=swap');
* {
	scroll-behavior: smooth;
}
.App {
	font-family: Poppins, sans-serif;
	background-color: #f9f9f9;
}

/******************* NAVBAR STYLING *******************/
nav {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 75px;
	background: white;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
nav .navbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	max-width: 90%;
	background: white;
	margin: auto;
}
nav .navbar .logo a {
	color: black;
	font-size: 27px;
	font-weight: 600;
	text-decoration: none;
}
nav .navbar .menu {
	display: flex;
}
.navbar .menu li {
	list-style: none;
	margin: 0 15px;
}
.navbar .menu li a {
	color: black;
	font-size: 17px;
	font-weight: 700;
	text-decoration: none;
}
.navbar .menu li a:hover {
	color: #ff5964;
}

/******************* HOME STYLING *******************/
.hero-background {
	margin: 50px;
	padding: 70px;
}
.hero-container {
	display: flex;
	justify-content: space-around;
	margin: 20px;
}

.hero-text h1 {
	font-size: 3rem;
}

.hero-text p {
	color: #555;
	font-size: 1.5rem;
	max-width: 70%;
}
.social-icons {
	color: black;
}

.social-icons:hover {
	color: #ff5964;
}

.hero-img {
	height: 300px;
	width: 300px;
	/* border: 3px solid black; */
	border-radius: 20%;
	background-image: url(../public/hero-img.png);
	background-position: 40% 100%;
}

.tech-stack {
	display: flex;
	justify-content: center;
	align-items: center;
}

.tech-stack p {
	text-align: center;
	padding: 20px;
}

.tech-stack ul {
	display: flex;
	justify-content: center;
	padding-left: 0;
}

.tech-stack li {
	list-style-type: none;
}

/**************** ABOUT STYLING ****************/
.about-container {
	display: flex;
	justify-content: space-evenly;
	background-color: white;
	padding: 80px;
}

.about-content {
	max-width: 400px;
}

.about-content p {
	color: #767676;
}

.about-content h2 {
	color: #ff5964;
	font-size: 1.275rem;
}

.about-img {
	height: 350px;
	border-radius: 5%;
	margin-top: 25px;
}

/********************* PORTFOLIO PIECES *******************/
.portfolio-container {
	padding: 100px;
}

.portfolio-header {
	margin-left: 40px;
	margin-bottom: 3rem;
}

.portfolio-header h3 {
	font-size: 1.5rem;
}

.card-details {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.right {
	text-align: center;
	padding: 30px;
	width: 90%;
	height: 300px;
	background-color: white;
	border-radius: 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 80px;
	box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}
.left {
	text-align: center;
	padding: 30px;
	width: 90%;
	height: 300px;
	background-color: white;
	border-radius: 15px;
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
	margin-bottom: 80px;
	box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.right p,
.left p {
	color: #767676;
}

.techUsed {
	margin: 20px;
	box-shadow: 0 0 10px rgba(0, 0, 0, .1);
	padding: 1rem 1.3rem;
	font-size: 1rem;
}

.techUsed + a {
	margin-top: 40px;
}

.right img,
.left img {
	border-radius: 10px;
	width: 100%;
	height: 80%;
	box-shadow: 0 0 10px rgba(0, 0, 0, .1);
	background-color: hsla(0, 0%, 46%, .2);
}
.right > div > h2,
.left > div > h2 {
	color: black;
}

.portfolio-container h2 {
	color: #ff5964;
	font-size: 1.275rem;
}

.project-links a {
	color: black;
	display: flex;
	justify-content: center;
	text-decoration: none;
}
.project-links p {
	margin-bottom: 40px;
}
div a:hover {
	color: #ff5964;
}

.image-wrap {
	width: 100rem;
	height: 300px;
	overflow: hidden;
	margin: 50px 10px;
}
.image-wrap img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: top center;
	transition: all 5s ease-in-out;
}
.image-wrap:hover img {
	object-position: bottom center;
}

/********************* CONTACT *******************/
.contact-container {
	padding: 100px;
	background-color: white;
}

.contact-container h2 {
	color: #ff5964;
	font-size: 1.275rem;
}

.contact-info {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.contact-content {
	display: flex;
	align-items: center;
}

.contact-content span {
	align-items: center;
	background-color: #fff;
	border-radius: 50%;
	box-shadow: 0 0 10px rgba(0, 0, 0, .1);
	color: #ff5964;
	display: flex;
	height: 45px;
	justify-content: center;
	width: 45px;
	margin: 20px;
}

.mail > a {
	text-decoration: none;
	color: black;
}

/********************* FOOTER *******************/
.footer-container {
	padding: 80px;
	background-color: #2d2e32;
}

.footer-content {
	color: white;
	display: flex;
	justify-content: space-between;
	font-weight: bold;
}

.footer-icons svg {
	color: white;
	margin-right: 5px;
}
.footer-icons svg:hover {
	color: #ff5964;
}

/********************* MEDIA QUERIES *******************/
@media (max-width: 1000px) {
	.about-container {
		display: flex;
		justify-content: space-around;
		background-color: white;
		padding: 80px 40px;
	}

	.portfolio-container {
		padding: 50px;
	}

	.right,
	.left {
		flex-direction: column-reverse;
		width: 60%;
		height: auto;
		padding: 20px;
	}

	.right > div > h2,
	.left > div > h2 {
		margin-top: -30px;
	}
	.image-wrap {
		margin-top: 40px;
		overflow: visible;
		width: 100%;
		height: 250px;
		margin-top: 0px;
	}
}
@media (max-width: 900px) {
	/* HOME MEDIA QUERIES */
	.hero-container {
		flex-direction: column-reverse;
		align-items: center;
	}
	.hero-text h1 {
		line-height: 3.5rem;
	}
	.hero-text {
		text-align: center;
	}
	.hero-text p {
		max-width: 100%;
	}
	.tech-stack {
		flex-direction: column;
	}
	.tech-stack p {
		text-align: center;
		padding: 0px !important;
	}
	/* ABOUT QUERIES */
	.about-container {
		flex-direction: column;
		text-align: center;
		align-items: center;
		justify-content: center;
	}

	/* PROJECT QUERIES */
}

@media (max-width: 767px) {
	.right > div > h2,
	.left > div > h2 {
		margin-top: -30px;
	}
	.image-wrap {
		margin-top: 40px;
		overflow: visible;
		width: 100%;
		height: 200px;
		margin-top: 0px;
	}
	.project-links p {
		font-size: .875rem;
		height: auto;
		padding: 10px 40px;
	}
	.techUsed {
		margin: 10px;
		padding: .5rem .5rem;
		font-size: .875rem;
	}
	nav .navbar .logo a {
		font-size: 20px;
	}
	.navbar .menu li {
		margin: 0 15px;
	}
	.navbar .menu li a {
		color: black;
		font-size: 12px;
		font-weight: 700;
		text-decoration: none;
	}
	.contact-container {
		text-align: center;
	}
	.contact-info {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.mail > a {
		font-size: 12px;
	}

	.contact-content span {
		width: 45px;
		margin: 20px;
	}
	.portfolio-header {
		text-align: center;
	}
	.footer-content {
		color: white;
		flex-direction: column;
		text-align: center;
	}
}

@media (max-width: 500px) {
	.image-wrap {
		margin-top: 40px;
		overflow: visible;
		width: 100%;
		height: 170px;
		margin-top: 0px;
	}
	.project-links p {
		font-size: .75rem;
		padding: 0;
	}
	.techUsed {
		margin: 5px;
		padding: .5rem .5rem;
		font-size: .5rem;
	}
	.portfolio-container h3 {
		font-size: 1rem;
	}

	.portfolio-container h2 {
		font-size: 1rem;
	}
	nav .navbar .logo a {
		font-size: 10px;
	}
	.navbar .menu li {
		margin: 0 15px;
	}
	.navbar .menu li a {
		color: black;
		font-size: 10px;
		font-weight: 700;
		text-decoration: none;
	}
}
